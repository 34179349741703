import React from "react"

// import Image from "../components/image"
// import SEO from "../components/seo"
import useTheme from "../../layout/theme/useTheme"

import {
  LegalContentRow,
  LegalParagraph,
  LegalLink,
  LegalSubHeadlines,
} from "./styled-legal"

function Disclosure() {
  const { spaces } = useTheme()
  return (
    <>
      <LegalContentRow>
        <LegalParagraph>
          Informationspflicht laut &sect;5 E-Commerce Gesetz, &sect;14
          Unternehmensgesetzbuch, &sect;63 Gewerbeordnung und
          Offenlegungspflicht laut &sect;25 Mediengesetz.
        </LegalParagraph>
        <LegalSubHeadlines>Firmenwortlaut &amp; Firmensitz</LegalSubHeadlines>
        <LegalParagraph>
          Into Numbers Data Science GmbH
          <br />
          Erdbergstrasse 10/67, <br />
          1030 Wien <br />
          &Ouml;sterreich
        </LegalParagraph>
        <LegalSubHeadlines>Kontakt</LegalSubHeadlines>
        <LegalParagraph>
          <strong>Tel.:</strong> +43 (0)720 701010
          <br />
          <strong>E-Mail:</strong> office@intonumbers.com
        </LegalParagraph>
        <LegalSubHeadlines>
          Informationspflichten lt. E-Commerce-Gesetz (ECG)
        </LegalSubHeadlines>
        <LegalParagraph>
          <strong>Unternehmensgegenstand:</strong>
          <strong>UID-Nummer:</strong> ATU72541868
          <br />
          <strong>Firmenbuchnummer:</strong> 476122f
          <br />
          <strong>Firmenbuchgericht:</strong> Handelsgericht Wien
          <br />
        </LegalParagraph>
        <LegalSubHeadlines>Unternehmensgegenstand</LegalSubHeadlines>
        <LegalParagraph>
          Dienstleistungen in der automatischen Datenverarbeitung und
          Informationstechnik
          <br />
          Sammeln und Weitergeben von allgemein zug&auml;nglichen Informationen
        </LegalParagraph>
        <LegalSubHeadlines>Geschäftsführer & Gesellschafter</LegalSubHeadlines>
        <LegalParagraph>Peter Just (100%)</LegalParagraph>
        <LegalSubHeadlines>
          Mitgliedschaften bei der Wirtschaftskammerorganisation
        </LegalSubHeadlines>
        <LegalParagraph>
          Mitglied der Wirtschaftskammer Wien
          <br />
          FG Gewerbliche Dienstleister
          <br /> FG Unternehmensberatung, Buchhaltung und
          Informationstechnologie
        </LegalParagraph>
        <LegalSubHeadlines>
          Anwendbare Rechtsvorschriften und Zugang dazu
        </LegalSubHeadlines>
        <LegalParagraph>
          Gewerbeordnung:{" "}
          <LegalLink
            title="www.ris.bka.gv.at"
            href="https://www.ris.bka.gv.at"
            target="_blank"
            rel="follow"
          >
            www.ris.bka.gv.at
          </LegalLink>
        </LegalParagraph>
        <LegalSubHeadlines>
          Beh&ouml;rde gem. ECG (E-Commerce Gesetz)
        </LegalSubHeadlines>
        <LegalParagraph>Bezirksamt des V. Bezirkes</LegalParagraph>
        <LegalSubHeadlines>
          Angaben zur Online-Streitbeilegung
        </LegalSubHeadlines>
        <LegalParagraph>
          Verbraucher haben die M&ouml;glichkeit, Beschwerden an die
          OnlineStreitbeilegungsplattform der EU zu richten:{" "}
          <LegalLink
            title="www.ec.europa.eu/odr"
            href="https://www.ec.europa.eu/odr"
            target="_blank"
            rel="follow"
          >
            www.ec.europa.eu/odr
          </LegalLink>{" "}
          <br />
          Wir m&ouml;chten Sie jedoch darauf hinweisen, dass wir nicht bereit
          oder verpflichtet sind, an Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teilzunehmen.
          <br /> Sie k&ouml;nnen allf&auml;llige Beschwerde auch an die oben
          angegebene E-Mail-Adresse richten.
        </LegalParagraph>
        <LegalSubHeadlines>DISCLAIMER</LegalSubHeadlines>
        <LegalParagraph style={{ margin: spaces.stack.xxl }}>
          F&uuml;r die Richtigkeit und Vollst&auml;ndigkeit der auf der Homepage
          www.intonumbers.com angebotenen Inhalte wird keinerlei Gew&auml;hr
          &uuml;bernommen. Den mittels Internet angebotenen Informationen kommt
          keinerlei Rechtsverbindlichkeit zu. Verschiedene Elemente auf diesen
          Seiten sind urheberrechtlich oder durch gesetzliche Grundlagen
          gesch&uuml;tzt. Wir m&ouml;chten ausdr&uuml;cklich betonen, dass wir
          keinerlei Einflu&szlig; auf die Gestaltung und die Inhalte von Seiten
          haben, auf die wir einen Link schalten. F&uuml;r die Inhalte und die
          Richtigkeit von gelinkten Seiten &uuml;bernehmen wir keinerlei
          Haftung.
        </LegalParagraph>
      </LegalContentRow>
    </>
  )
}

export default Disclosure
