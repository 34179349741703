import React from "react"

import SEO from "../components/SEO"

import Layout from "../layout"

import Disclosure from "../containers/Legal/Disclosure"

function Legal() {
  return (
    <Layout>
      <SEO
        title="Impressum"
        keywords={[
          `Into Numbers`,
          `Data Science`,
          `Web Development`,
          `Digitalisierung`,
          `Trainings`,
          `Business Intelligence`,
          `Impressum`,
        ]}
      />
      <Disclosure />
    </Layout>
  )
}

export default Legal
